import React , {useState,useEffect} from 'react';
import {useTranslation} from "react-i18next"; 
import ScrollToTop from './../ScrollToTop';

const Coverage  = ()=>{
   const {t} = useTranslation();
   const [items, setItems] = useState(null);
 
      useEffect( () => {
           fetch(`./data/coverage.json`)
           .then(res => res.json())
           .then(data => setItems(data));
       }, []);
 
       //pagination
        const [currentPage, setcurrentPage] = useState(1);
        const [ItemPerPage, setItemPerPage] = useState(30);

        const lastProducts=currentPage * ItemPerPage; 
        const firstProducts=lastProducts - ItemPerPage;
        const currentItems=items && items.slice(firstProducts,lastProducts); 
        
        const totalPage=Math.ceil(items && items.length / ItemPerPage);
        
        const HandlePrevPage = ()=>{
            setcurrentPage(currentPage - 1);
        }
        const HandleNextPage = ()=>{
            setcurrentPage(currentPage + 1);
        }

    return (
        <>
           <ScrollToTop/>
        <div name='coverage' className='container px-4 mx-auto py-10' style={{ marginTop:"100px" }} data-aos="fade-up-right" data-aos-duration="1500" data-aos-delay="1100">
            
            {/* Contact title Start */}
            <div className="text-4xl font-semibold text-primary flex justify-center mb-10">
            <div>
                <h2 className="">{t("coverage")}</h2>
                <div className="border border-t-2 border-t-primary w-16 mt-3"></div>
            </div>
        </div>
        {/* Contact title end  */}
 
            <div className="overflow-x-auto">
                <table className="table table-zebra w-full" id='myTable'>
                    {/* head */}
                    <thead>
                    <tr>
                        <th >#</th>
                        <th>{t('district')}</th>
                        <th>{t('area')}</th>
                        <th>{t('post_code')}</th>
                        <th>{t('charge_1kg')}</th>
                        <th>{t('charge_2kg')}</th>
                        <th>{t('charge_3kg')}</th>
                        <th>{t('cod_charge')}</th>
                    </tr>
                    </thead>
                    <tbody> 
                    {currentItems && currentItems.map((item,index)=>{
                            return (
                                <tr key={index}>
                                    <th>{1+index}</th>
                                    <td>{item.district}</td>
                                    <td>{item.area}</td>
                                    <td>{item.post_code}</td>
                                    <td>{item.charge_1k}</td>
                                    <td>{item.charge_2k}</td>
                                    <td>{item.charge_3k}</td>
                                    <td>{item.code_charge}</td>
                                </tr>   
                            );
                    })}
                    </tbody>
                </table>
            </div>
                <div className='mt-4'> 
                    <button type='button' className='float-left btn w-[100px] border-none font-bold hover:text-white bg-primary text-white bg-primary text-white' onClick={HandlePrevPage} disabled={currentPage ===1 ? 'disabled' : ''}>Previus</button>
                     <button className=' float-right btn w-[100px] border-none font-bold hover:text-white bg-primary text-white bg-primary text-white' type='button'  onClick={HandleNextPage} disabled={currentPage === totalPage ? "disabled":""} >Next</button>
                </div>
    </div>
    </>
    );
}

export default Coverage;
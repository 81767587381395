import React from 'react'

const Register = ()=>{
  return (
        <div>
            <input type="checkbox" id="merchant-register" className="modal-toggle" />
            <div className="modal">
            <div className=" w-11/12 max-w-5xl">
                {/* Modal container started  */}
                <div className="flex justify-center w-full my-10">
                <div className="lg:w-2/6 md:w-1/2 bg-white relative shadow-xl border rounded-lg p-8 flex flex-col text-center w-full mt-10 md:mt-0">
                    <label htmlFor="merchant-register" className="btn btn-sm btn-circle absolute right-2 top-2 bg-primary border-primary hover:bg-primary">✕</label>
                    <h2 className="text-gray-900 text-lg font-medium title-font mb-5">Join As Merchant</h2>
                    <div className="relative mb-4">
                    <input type="text" placeholder="Full Name" className="w-full bg-white rounded border border-gray-300 focus:border-primary focus:ring-2 focus:ring-orange-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                    </div>
                    <div className="relative mb-4">
                    <input type="tel" placeholder="Phone Number" className="w-full bg-white rounded border border-gray-300 focus:border-primary focus:ring-2 focus:ring-orange-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                    </div>
                    <div className="relative mb-4">
                    <input type="email" placeholder="Email" className="w-full bg-white rounded border border-gray-300 focus:border-primary focus:ring-2 focus:ring-orange-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                    </div>
                    <div className="relative mb-4">
                    <input type="password" placeholder="Password" className="w-full bg-white rounded border border-gray-300 focus:border-primary focus:ring-2 focus:ring-orange-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                    </div>
                    <button className="text-white bg-primary border-0 py-2 px-8 focus:outline-non rounded text-lg">Register</button>
                    <a href="#" className="text-xs text-primary mt-3">Forgot your password?</a>
                </div>
                </div>
                {/* Modal container ended  */}
            </div>
            </div>
        </div>
    )
}
export default Register;
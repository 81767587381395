import React, {useEffect,useState} from 'react';
import axios from 'axios';
const Login = ()=>{
 const [Formdata, setFormData] = useState({email:'',password:''});
  const changeHanlder = (e)=>{
       setFormData({...Formdata,[e.target.name]:e.target.value});
  }
 
  const loginHandler = (e)=>{
      e.preventDefault();
        axios.post(`${process.env.REACT_APP_BASE_URL}/api/v10/signin`,Formdata)
        .then((res)=>{
            console.log(res.data.data['token']);
        })
        .catch((error)=>{
            console.log(error);
        });
  }
 
  
  return (
    <div>
        <input type="checkbox" id="merchant-login" className="modal-toggle" />
        <div className="modal  ">
            <div className=" w-11/12 max-w-5xl">
              <form onSubmit={loginHandler}>
                  {/* Modal container started  */}
                  <div className="flex justify-center w-full my-10">
                    <div className="lg:w-2/6 md:w-1/2 bg-white relative shadow-xl border rounded-lg p-8 flex flex-col text-center w-full mt-10 md:mt-0">
                    <label htmlFor="merchant-login" className="btn btn-sm btn-circle bg-primary border-primary hover:bg-primary absolute right-2 top-2">✕</label>
                        <h2 className="text-gray-900 text-lg font-medium title-font mb-5">Login As Merchant</h2>
                        <div className="relative mb-4">
                        <input type="email" placeholder="Email" className="w-full bg-white rounded border border-gray-300 focus:border-primary focus:ring-2 focus:ring-orange-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" name='email' onChange={changeHanlder} required />
                        </div>
                        <div className="relative mb-4">
                        <input type="password" placeholder="Password" className="w-full bg-white rounded border border-gray-300 focus:border-primary focus:ring-2 focus:ring-orange-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" name='password' onChange={changeHanlder} />
                        </div>
                        <button type='submit' className="text-white bg-primary border-0 py-2 px-8 focus:outline-none rounded text-lg">Login</button>
                        <a href="#" className="text-xs text-primary mt-3">Forgot your password?</a>
                        <label htmlFor="merchant-register" className={`text-xs text-primary mt-3 cursor-pointer`}>Register as Merchant</label>
                    </div>
                  </div>
                  {/* Modal container ended  */} 
              </form>
            </div>
        </div>
    </div>
  )
}
export default Login;
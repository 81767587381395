import {useTranslation} from "react-i18next"; 
const Service = ({service})=>{
    const id  = parseInt(service.id);
    const { t } = useTranslation();
    return (
        <div className={`${id === 1  ? "lg:mt-16": ""} ${id === 2  ? "lg:-mt-8": ""} ${id === 3  ? "lg:-mt-16": ""} ${id === 4  ? "lg:-mt-24": ""}`} data-aos="fade-up-left" data-aos-duration="1000">
            <div className={` service card rounded-lg my-4 bg-base-100 shadow-xl hover:scale-110 hover:bg-primary hover:text-white transition ease-in-out delay-150 duration-300`}>
                <figure>
                    <img src={service.img} className="w-50 max-h-50" alt="Shoes" />
                </figure>
                <div className="card-body">
                    <h2 className="card-title text-center">{t(service.name)}</h2>
                    <p>{t(service.name+'_text')}</p>
                </div>
            </div> 
        </div>
    );
}
export default Service;
import React from 'react'
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import axios from 'axios';
import {useTranslation} from "react-i18next"; 
export default function Subscribe() {

    const { t } = useTranslation();
    
    const { register, formState: { errors }, handleSubmit ,reset} = useForm();

    const onSubmit = (data) =>{ 
        
            const loading = toast.loading('Please waiting...', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme:'dark', 
            });
     
      
        axios.post(`${process.env.REACT_APP_BASE_URL}/api/v10/subscribe`,data)
        .then((res)=>{  
         
            if(res.data.data.exists){
                toast.update(loading, { render: res.data.message, type: "error",  autoClose: 5000, isLoading: false });
            }else{

                toast.update(loading, { render: res.data.message, type: "success",  autoClose: 5000, isLoading: false });
            }

            reset({email: ''});
          
        })
        .catch((error)=>{
            
            toast.update(loading, { render: 'Something went wrong.', type: "error",  autoClose: 5000, isLoading: false });
            console.log(error);
        });
        
    };


  return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex md:flex-nowrap flex-wrap justify-center items-end md:justify-start">
                    <div className="relative sm:w-64 w-40 sm:mr-4 mr-2">
                        <input type="email"{...register("email", { required: true })}  placeholder={t("email")} className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:ring-2 focus:bg-transparent focus:ring-orange-200 focus:border-orange-500 text-base outline-none text-gray-700 py-1 px-3 transition-colors duration-200 ease-in-out"/>
                        <p className='text-red-500'>{errors.email?.type === 'required' && "Email field is required"}</p>
                    </div>
                    <button type='submit' className="inline-flex text-white bg-primary text-black border-0 py-1 px-6 focus:outline-none hover:bg-orange-600 rounded">{t('subscribe')}</button>
                </div>
            </form>
        );
    
}




import {useState , useEffect} from 'react';
import Service from './Service';   
import {useTranslation} from "react-i18next"; 
const Services = ()=>{ 
    const {t} = useTranslation();
    const [services, setServices] = useState([]);

    useEffect( () => {
        fetch('./data/services.json')
        .then(res => res.json())
        .then(data => setServices(data));
    }, []);

 

    return (
        <section name="services" className="mt-48 container px-10 mx-auto mb-20">
            <div className="text-4xl font-semibold text-primary -mt-32 max-w-sm">
                <h2 className="">{t('services_we_provide')}</h2>
                <div className="border border-t-2 border-t-primary w-16 mt-3"></div>
            </div>
            <div className="mt-20">
                <div className="grid  md:grid-cols-2 lg:grid-cols-4 gap-x-8">
                    {
                        services.map(service => <Service key={service.id} service={service}></Service>)
                    }
                </div>
            </div>
        </section>
    );
}


export default Services;
import Moment from 'react-moment';
import { useTranslation } from 'react-i18next';
import {
    PENDING,
    PICKUP_ASSIGN,
    PICKUP_RE_SCHEDULE,
    RECEIVED_BY_PICKUP_MAN,
    RECEIVED_WAREHOUSE,
    TRANSFER_TO_HUB,
    DELIVERY_MAN_ASSIGN,
    DELIVERY_RE_SCHEDULE,
    DELIVERED,
    DELIVER,
    RETURN_WAREHOUSE,
    ASSIGN_MERCHANT,
    RETURNED_MERCHANT,
    RECEIVED_BY_HUB,
    RETURN_TO_COURIER,
    RETURN_ASSIGN_TO_MERCHANT,
    RETURN_MERCHANT_RE_SCHEDULE,
    RETURN_RECEIVED_BY_MERCHANT,
    PARTIAL_DELIVERED
} from './Status';


const LogRight = ({event})=>{

    const {t} = useTranslation();

      let status  = '';
      switch (event.parcel_status) {
        case  PICKUP_ASSIGN :
            status = t('pickup_assign');
        break;
        case  PICKUP_RE_SCHEDULE :
            status = t('pickup_re_schedule');
        break;
        case  RECEIVED_BY_PICKUP_MAN :
            status = t('received_by_pickup_man');
        break;
        case  RECEIVED_WAREHOUSE :
            status = t('received_by_warehouse');
        break;
        case  TRANSFER_TO_HUB :
            status = t('transfer_to_hub');
        break;
        case  DELIVERY_MAN_ASSIGN :
            status = t('delivery_man_assign');
        break;
        case  DELIVERY_RE_SCHEDULE :
            status = t('delivery_re_schedule');
        break;
        case  DELIVERED :
            status = t('deliverd');
        break;
        case  DELIVER :
            status = t('deliver');
        break;
        case  RETURN_WAREHOUSE :
            status = t('return_warehouse');
        break;
        case  ASSIGN_MERCHANT :
            status = t('assign_merchant');
        break;
        case  RETURNED_MERCHANT :
            status = t('return_merchant');
        break;
      
        case  RECEIVED_BY_HUB :
            status = t('received_by_hub');
        break;
        case  RETURN_TO_COURIER :
            status = t('return_to_couirier');
        break;
        case  RETURN_ASSIGN_TO_MERCHANT :
            status = t('return_assign_to_merchant');
        break;
        case  RETURN_MERCHANT_RE_SCHEDULE :
            status = t('return_merchant_re_schedule');
        break;
        case  RETURN_RECEIVED_BY_MERCHANT :
            status = t('return_received_by_merchant');
        break;
        case  PARTIAL_DELIVERED :
            status = t('partial_deliverd');
        break;

        default:
        break;
      }
   

      if(event){
          return (
                    <>
                        <div className="container max-width-lg cd-timeline__container"> </div>
                        <div className="container max-width-lg cd-timeline__container">
                            <div className="right cd-timeline__block  " style={{ overFlow:'hidden' }}>
                                <div className={`cd-timeline__img cd-timeline__img--picture float-left ${event && event.parcel_status == 3 || event.parcel_status == 8 || event.parcel_status == 27  ? `bg-warning`:''}` }>
                                    {event && event.parcel_status == 3 || event.parcel_status == 8 || event.parcel_status == 27  ? 
                                    <i className="bi bi-hourglass" />:<i className="bi bi-check" />  }
                                    {/*  */}
                                {/* fas fa-hourglass-end */}
                                </div>  
                                <div className="right cd-timeline__content text-component shadow-xl rounded-lg float-right ">
                                    <h2 className="text-2xl text-primary title">{status}</h2> 
                                    {event.merchant_name &&
                                        <div className="flex mt-3">
                                            <b className="w-[100px]">{t('name')}</b>
                                            <p className="color-contrast-medium">: {event.merchant_name} </p>
                                        </div>
                                    }
                                    {event.email &&
                                        <div className="flex mt-3">
                                        <b className="w-[100px]">{t('email')} </b>
                                        <p className="color-contrast-medium">: {event.email }</p>
                                        </div>
                                    }
                                    {event.delivery_man && 
                                        event.delivery_man.user ? 
                                            <div className="flex mt-3">
                                                <b className="w-[100px]">{t('delivery_man')} </b>
                                                <p className="color-contrast-medium">: {event.delivery_man.user.name} </p>
                                            </div>:''
                                    }

                                    {event.delivery_man && 
                                        event.delivery_man.user ? 
                                            <div className="flex mt-3">
                                                <b className="w-[100px]">{t('phone')} </b>
                                                <p className="color-contrast-medium">: {event.delivery_man.user.mobile} </p>
                                            </div>:''
                                    }


                                    {event.pickupman && 
                                        event.pickupman.user ? 
                                            <div className="flex mt-3">
                                                <b className="w-[100px]">{t('pickup_man')} </b>
                                                <p className="color-contrast-medium">: {event.pickupman.user.name} </p>
                                            </div>:''
                                    }

                                    {event.pickupman && 
                                        event.pickupman.user ? 
                                            <div className="flex mt-3">
                                                <b className="w-[100px]">{t('phone')} </b>
                                                <p className="color-contrast-medium">: {event.pickupman.user.mobile} </p>
                                            </div>:''
                                    }

                                    {event.hub && 
                                        
                                            <div className="flex mt-3">
                                                <b className="w-[100px]">{t('hub_name')}</b>
                                                <p className="color-contrast-medium">: {event.hub.name} </p>
                                            </div> 
                                    }

                                    {event.hub && 
                                      
                                            <div className="flex mt-3">
                                                <b className="w-[100px]">{t('phone')} </b>
                                                <p className="color-contrast-medium">: {event.hub.phone} </p>
                                            </div> 
                                    }

                                    {event &&  
                                        <div className="flex mt-3">
                                            <b className="w-[100px]">{t('note')} </b>
                                            <p className="color-contrast-medium">: {event.note} </p>
                                        </div>
                                    }
                                
                                    {event.created_at &&
                                        <div className="flex justify-between items-center">
                                            <span className="right cd-timeline__date">
                                                <strong>
                                                    <Moment format="D MMM YYYY" >{event && event.created_at}</Moment>
                                                </strong><br/>
                                                    <Moment format="hh:mm:ss">{event && event.created_at}</Moment>
                                            </span>
                                        </div>
                                    }
                                </div> 
                            </div>
                            <div className="cd-timeline__block"></div> 
                        </div>
                    </>
                 );
      }
      return null;
  }
  export default LogRight;
import { Route, Routes } from "react-router-dom";
import './App.css';
import Home from './components/Pages/Home';

import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from "./components/Shared/Footer";
import Header from "./components/Shared/Header";

import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import axios from 'axios'; 
import './i18n';

import Track from './components/Pages/Track';
import Coverage from './components/Pages/Coverage';
import PrivacyPolicy from './components/Pages/PrivacyPolicy';
import TermsAndCondition from './components/Pages/TermsAndCondition';


axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
AOS.init()

function App() {
  return (
    <div className="App">
        <Header></Header>
       
          <Routes>
            <Route path='/' element={<Home></Home>}></Route>
            <Route exact path='/home' element={<Home></Home>}></Route>
            <Route exact path='/track/:tracking_id' element={<Track></Track>}></Route>
            <Route exact path='/coverage' element={<Coverage></Coverage>}></Route>
            <Route exact path='/privacy-policy' element={<PrivacyPolicy></PrivacyPolicy>}></Route> 
            <Route exact path='/terms-condition' element={<TermsAndCondition></TermsAndCondition>}></Route>
          </Routes>
     
        <Footer></Footer>

        {/* Merchant Login Modal start */}
          <Login/>
        {/* Merchant Login Modal End  */}

        {/* Merchant Register Modal start */}
          <Register/>
        {/* Merchant Register Modal End  */}

      </div>
  );
}

export default App;

const PENDING                           = 1;
const PICKUP_ASSIGN                     = 2;
const PICKUP_RE_SCHEDULE                = 3;
const RECEIVED_BY_PICKUP_MAN            = 4;
const RECEIVED_WAREHOUSE                = 5;
const TRANSFER_TO_HUB                   = 6;
const DELIVERY_MAN_ASSIGN               = 7;
const DELIVERY_RE_SCHEDULE              = 8;
const DELIVERED                         = 9;
const DELIVER                           = 10;
const RETURN_WAREHOUSE                  = 11;
const ASSIGN_MERCHANT                   = 12;
const RETURNED_MERCHANT                 = 13;
const RECEIVED_BY_HUB                   = 19;
const RETURN_TO_COURIER                 = 24;
const RETURN_ASSIGN_TO_MERCHANT         = 26;
const RETURN_MERCHANT_RE_SCHEDULE       = 27;
const RETURN_RECEIVED_BY_MERCHANT       = 30;
const PARTIAL_DELIVERED                 = 32;


export  {
        PENDING,
        PICKUP_ASSIGN,
        PICKUP_RE_SCHEDULE,
        RECEIVED_BY_PICKUP_MAN,
        RECEIVED_WAREHOUSE,
        TRANSFER_TO_HUB,
        DELIVERY_MAN_ASSIGN,
        DELIVERY_RE_SCHEDULE,
        DELIVERED,
        DELIVER,
        RETURN_WAREHOUSE,
        ASSIGN_MERCHANT,
        RETURNED_MERCHANT,
        RECEIVED_BY_HUB,
        RETURN_TO_COURIER,
        RETURN_ASSIGN_TO_MERCHANT,
        RETURN_MERCHANT_RE_SCHEDULE,
        RETURN_RECEIVED_BY_MERCHANT,
        PARTIAL_DELIVERED
    }
import Moment from 'react-moment';
import { useTranslation } from 'react-i18next';
const CreateLog = ({event})=>{
    const {t} = useTranslation();
    return (
            <>
                <div className="container max-width-lg cd-timeline__container"> </div>
                <div className="container max-width-lg cd-timeline__container  ">
                    <div className="right cd-timeline__block  " style={{ overFlow:'hidden' }}>
                        <div className={`cd-timeline__img cd-timeline__img--picture float-left ${event && event.parcel_status == 3 || event.parcel_status == 8 || event.parcel_status == 27  ? `bg-warning`:''}` }>
                            {event && event.parcel_status == 3 || event.parcel_status == 8 || event.parcel_status == 27  ? 
                            <i className="bi bi-hourglass" />:<i className="bi bi-check" />  }
                            {/*  */}
                            {/* fas fa-hourglass-end */}
                        </div>  
                        <div className="right cd-timeline__content text-component shadow-xl rounded-lg float-right ">
                            <h2 className="text-2xl text-primary title">{t('parcel_create')}</h2> 
                            {event.merchant_name &&
                                <div className="flex mt-3">
                                    <b className="w-[100px]">Name </b>
                                    <p className="color-contrast-medium">: {event.merchant_name} </p>
                                </div>
                            }
                            {event.email &&
                                <div className="flex mt-3">
                                <b className="w-[100px]">Email </b>
                                <p className="color-contrast-medium">: {event.email }</p>
                                </div>
                            }
                            {event.mobile &&
                                <div className="flex mt-3">
                                <b className="w-[100px]">Phone </b>
                                <p className="color-contrast-medium">: {event.mobile} </p>
                                </div>
                            }
                        
                            {event.created_at &&
                                <div className="flex justify-between items-center">
                                    <span className="right cd-timeline__date">
                                        <strong>
                                            <Moment format="D MMM YYYY" >{event && event.created_at}</Moment>
                                        </strong><br/>
                                            <Moment format="hh:mm:ss">{event && event.created_at}</Moment>
                                    </span>
                                </div>
                            }
                        </div> 
                    </div>
                    <div className="cd-timeline__block"></div> 
                </div>
            </>
        )
  }
  export default CreateLog;
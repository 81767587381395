import { useTranslation } from "react-i18next";
import ScrollToTop from "../ScrollToTop";
const TermsCondition = () => {
    const { t } = useTranslation();
    return (
        <>
            <ScrollToTop />
            <div name='termscondition' className='container px-4 mx-auto py-10' style={{ marginTop: "100px" }} data-aos="fade-up-right" data-aos-duration="1500" data-aos-delay="1100">
                {/* Contact title Start */}
                <div className="text-4xl font-semibold text-primary flex justify-center mb-10">
                    <div>
                        <h2 className="">{t("terms_conditions")}</h2>
                        <div className="border border-t-2 border-t-primary w-16 mt-3"></div>
                    </div>
                </div>
                {/* Contact title end  */}
                <div >
                    <h3 style={{ fontWeight: "bold", fontSize: "20px" }}>{t('last_updated')}</h3>
                    <p>20-08-2023</p>
                </div>

                <div className="mt-3">
                    <h3 style={{ fontWeight: "bold", fontSize: "20px" }}>Denominación Social: MENSAJERIA SHALOM</h3>
                    <h3 style={{ fontWeight: "bold", fontSize: "20px" }}>NIT: 4621949016</h3>
                    <h3 style={{ fontWeight: "bold", fontSize: "20px" }}>APP: SHALOM APP</h3>
                    <h3 style={{ fontWeight: "bold", fontSize: "20px" }}>1.- GENERALIDADE</h3>
                </div>


                <div className='mt-5' >
                    <h3 style={{ fontWeight: "bold", fontSize: "20px" }}>1.1 NATURALEZA DE SHALOM APP</h3>
                </div>

                <div className='mt-5'>
                    <p>SHALOM APP es una Plataforma digital administrada por la empresa MENSAJERIA
                        SHALOM, que conecta franquiciados y licenciatarios de MENSAJERIA SHALOM con
                        diferentes Usuarios, para que estos puedan solicitar el envío de mensajería y delivery.
                    </p>
                    <p className="mt-2">MENSAJERIA SHALOM no brinda servicios de entrega y/o envío de correspondencia
                        por medio de la presente Aplicación.
                    </p>
                    <p className="mt-2"> En caso que el Usuario requiera los servicios directos de MENSAJERIA SHALOM,
                        puede realizarlo por medio del siguiente contacto:<mark  > (INSERTAR CORREO
                            ELECTRONICO)</mark></p>
                </div>

                <div className='mt-5' >
                    <h3 style={{ fontWeight: "bold", fontSize: "20px" }}>1.2 USUARIOS </h3>
                </div>

                <div className='mt-5'>
                    <p>Cualquier persona (en adelante Usuario) que desee acceder y/o suscribirse y/o usar
                        SHALOM APP o los Servicios que ofrece podrá hacerlo sujetándose a los Términos
                        y Condiciones Generales, junto con todas las demás políticas y principios que rigen a
                        SHALOM APP.</p>
                    <p className="mt-2">
                        Los presentes Términos y Condiciones son aplicables a todos los Usuarios que
                        empleen la Aplicación.

                    </p>
                </div>

                <div className='mt-5' >
                    <h3 style={{ fontWeight: "bold", fontSize: "20px" }}>1.2.1 CAPACIDAD LEGAL</h3>
                </div>

                <div className='mt-5'>
                    <p>
                        El Usuario acepta ser mayor de edad y hábil por derecho, para realizar actos de
                        comercio dentro de la plataforma.
                    </p>
                    <p className="mt-2">
                        El Usuario debe aceptar los presentes Términos y Condiciones para utilizar SHALOM
                        APP, caso contrario deberá abstenerse de utilizarlo así como sus servicios.
                        El uso de la presente Plataforma implica la aceptación de las condiciones establecidas
                        en el presente documento, por ello el Usuario se obliga a cumplir los presentes
                        Términos y Condiciones, no pudiendo alegar el desconocimiento de los mismos.
                    </p>
                    <p className="mt-2">
                        Todas las acciones, contratos y transacciones que se realicen en y con SHALOM
                        APP, así como sus efectos jurídicos en lo que concierne al uso de la aplicación en si
                        misma, quedarán regidos por los presentes términos y condiciones y sometidos a la
                        legislación aplicable en Bolivia en lo respectivo a las leyes civiles, comerciales, firma
                        electrónica, documento electrónico y derechos del consumidor.

                    </p>
                </div>


                <div className='mt-5' >
                    <h3 style={{ fontWeight: "bold", fontSize: "20px" }}>2.- RESPONSABILIDAD</h3>
                </div>

                <div className='mt-5'>
                    MENSAJERIA SHALOM prestará el servicio con la máxima eficiencia posible, dados
                    los medios y las tecnologías actualmente existentes; no obstante, el Usuario debe ser
                    consciente de que MENSAJERIA SHALOM no puede garantizar plenamente la
                    fiabilidad de los servicios deSHALOM APP, ya que se pueden producir incidencias
                    puntuales en las utilidades puestas a disposición de los Usuarios, por lo que
                    MENSAJERIA SHALOM no será responsable por los siguientes hechos:


                    <ul style={{ listStyle: 'disc' }}>
                        <li>Por cualquier daño, perjuicio o pérdida al Usuario causados por fallas en
                            el sistema, en el servidor o en Internet.
                        </li>

                        <li>Por cualquier virus que pudiera infectar el equipo del Usuario como
                            consecuencia del acceso, uso o examen de su sitio web o a raíz de
                            cualquier transferencia de datos en el mismo. Los Usuarios no podrán
                            imputarle responsabilidad alguna ni exigir pago por lucro cesante, en
                            virtud de perjuicios resultantes de dificultades técnicas o fallas en los
                            sistemas o en Internet.
                        </li>
                        <li>Por el acceso interrumpido de su sitio. El sistema puede eventualmente
                            no estar disponible debido a dificultades técnicas o fallas de Internet, o
                            por cualquier otra circunstancia ajena a MENSAJERIA SHALOM; en
                            tales casos se procurará restablecerlo con la mayor celeridad p </li>

                        <li>Por cualquier consecuencia por la pérdida o daño indirecta o directa.
                            MENSAJERIA SHALOM no será responsable por cualquier pérdida de
                            ganancias, ingreso, rentas, contratos, negocios, reputación, datos o
                            información.
                        </li>
                        <li> MENSAJERIA SHALOM no será responsable por cualquier pérdida o
                            daño resultante de cualquier evento o eventos fuera del control
                            razonable de MENSAJERIA SHALOM</li>
                        <li>Por actos realizados por parte de Terceros sean estos franquiciados o licenciatarios. </li>

                    </ul>
                </div>
                <div className="mt-5">
                    <h3 style={{ fontWeight: "bold", fontSize: "20px" }}>4.- SERVICIOS BRINDADOS POR TERCEROS</h3>
                    Comentado [DR1]: Con esto nos hacemos
                    responsables porque somos los administradores,
                    pensar en alternativas
                    Comentado [DR2]: Aquí aclarar bien cuál es la
                    empresa y cuál la APP
                    SHALOM APP es una Plataforma que pone a disposición su tecnología y
                    herramientas para conectar conductores y/o mensajeros, franquiciados o
                    licenciatarios con Usuarios, por lo que los servicios de i) envio de mensajería, y ii)
                    Delivery, solicitados por medio de la presente plataforma digital se realizarán
                    mediante personas naturales o juridicas y/o terceros ajenos a MENSAJERIA
                    SHALOM.
                    <h3 className="mt-5" style={{ fontWeight: "bold", fontSize: "20px" }}>5.- PROPIEDAD INTELECTUAL</h3>
                    MENSAJERIA SHALOM es titular o ha obtenido la correspondiente licencia sobre los
                    derechos de explotación de propiedad intelectual e industrial de la Plataforma así
                    como de los derechos de propiedad intelectual e industrial y cualesquiera otros
                    análogos sobre la información, materiales, contenidos, la selección y/o disposición de
                    los contenidos de la Plataforma.
                    En ningún caso se entenderá que el acceso, navegación y utilización de SHALOM
                    APP por parte del Usuario implica una renuncia, transmisión, licencia o cesión total o
                    parcial de dichos derechos por parte de MENSAJERIA SHALOM. El Usuario dispone
                    de un derecho de uso de los contenidos y/o servicios de SHALOM APP dentro de un
                    ámbito estrictamente doméstico.
                    Las referencias o la mera incorporación en la Plataforma SHALOM APP de marcas
                    o nombres comerciales registrados, u otros signos distintivos registrados o no
                    registrados, cuyos derechos de propiedad intelectual, industrial o cualesquiera otros
                    análogos que son titularidad de MENSAJERIA SHALOM o que se están utilizando
                    con la correspondiente licencia, llevan implícitas la prohibición sobre su uso sin el
                    consentimiento de MENSAJERIA SHALOM o de sus legítimos titulares. En ningún
                    momento, salvo manifestación expresa en contrario, el acceso, navegación o
                    utilización de la Plataforma o de sus contenidos, confiere al Usuario derecho alguno
                    sobre signos distintivos en él incluidos.
                    Quedan reservados todos los derechos de propiedad intelectual e industrial sobre los
                    contenidos y/o servicios de la Plataforma y, en particular, queda prohibido modificar,
                    copiar, reproducir, comunicar públicamente, transformar o distribuir, por cualquier
                    medio y bajo cualquier forma, la totalidad o parte de los contenidos incluidos en la
                    Plataforma, para propósitos públicos o comerciales, si no se cuenta con la
                    autorización previa, expresa y por escrito de MENSAJERIA SHALOM o, en su caso,
                    del titular de los derechos correspondientes.
                    En el caso de que el Usuario envíe información de cualquier tipo a MENSAJERIA
                    SHALOM a través de cualquiera de los accesos que facilita MENSAJERIA SHALOM,
                    el Usuario declara, garantiza y acepta que tiene derecho a hacerlo libremente, que la
                    información no infringe ningún derecho de propiedad intelectual, industrial, secreto
                    comercial o cualesquiera otros derechos de terceros, y que no tiene carácter
                    confidencial ni es perjudicial para terceros.
                    <h3 className="mt-5" style={{ fontWeight: "bold", fontSize: "20px" }}>6.- PROHIBICIONES</h3>
                    Los Usuarios tendrán prohibido:

                    <ul style={{ listStyle: 'disc', marginLeft: "2rem" }}>
                        <li> Descargar, adquirir o utilizar las imágenes o fotografías no autorizadas o de
                            terceros sin su previo consentimiento. </li>
                        <li>Infringir derechos de propiedad intelectual y de Propiedad Industrial y Derechos
                            de autor en particular por cualquier medio.</li>
                        <li>Extraer y/o utilizar información, imágenes o fotografías de otros Usuarios sin
                            autorización expresa.</li>
                        <li>Recopilar información de otros Usuarios,</li>
                        <li>Acceder de otro modo a los Sitios Web utilizando medios automáticos (como
                            harvesting bots, robots, arañas o scrapers).</li>
                        <li> Copiar la información o contenidos publicados en la Plataforma, para
                            publicarlos en otras páginas web.</li>
                        <li>Tratar de introducir un virus informático o ingresar en la base de datos o
                            servidores que sean empleados por MENSAJERIA SHALOM.</li>
                        <li> Obstaculizar, restringir o limitar el uso de los servicios a otros Usuarios.</li>
                        <li>Usar los servicios de los Sitios Web para finalidades prohibidas por ley, o que
                            vayan contra la moral y/o las buenas costumbres.</li>
                        <li>En general, hacer un uso inadecuado de los servicios ofrecidos por los Sitios
                            Web que MENSAJERIA SHALOM considere que podría causar un daño o
                            perjuicio a la propia Empresa, sus Clientes o a los otros Usuarios.
                            MENSAJERIA SHALOM se reserva el derecho a decidir unilateralmente, si un
                            determinado uso de los servicios por parte de un Usuario se aparta del objeto
                            para el cual MENSAJERIA SHALOM y/o los servicios ofrecidos fueron creados
                            pudiendo dar de baja automática al Usuario y/o productos o bienes en
                            infracción que estuvieren siendo ofrecidos o comercializados.</li>
                        <li> Usar la información a que se tiene acceso como Usuario con propósitos
                            comerciales, sin la autorización expresa y escrita previa de MENSAJERIA
                            SHALOM. En el caso que se utilice con fines no comerciales, periodísticos,
                            académicos u otros, deberá ser citado como fuente.</li>
                    </ul>
                    <h3 className="mt-5" style={{ fontWeight: "bold", fontSize: "20px" }}>7.- INCUMPLIMIENTO</h3>
                    Sin perjuicio a otros derechos de MENSAJERIA SHALOM bajo estos términos y
                    condiciones de uso, el incumplimiento por parte de los Usuarios en cualquier modo,
                    habilitará a MENSAJERIA SHALOM para resolver automáticamente el contrato sin
                    reconocimiento de pago o indemnización y tomar la acción que considere apropiada
                    para lidiar con la infracción, incluyendo la suspensión del acceso a la Aplicación y/o
                    tomar acciones legales contra el infractor, para el resarcimiento de los daños y
                    perjuicios ocasionados por el incumplimiento de los presentes términos y condiciones
                    establecidos.
                    <h3 className="mt-5" style={{ fontWeight: "bold", fontSize: "20px" }}>8. RECLAMACIONES</h3>
                    MENSAJERIA SHALOM, no emplea la presente Aplicación para el envió de
                    mensajería y delivery, por lo que los servicios de mensajería y delivery son
                    responsabilidad de las personas naturales o jurídicas, franquiciadas o licenciatarias,
                    terceros ajenos con quienes las reclamaciones de los servicios operan conforme a la
                    normativa legal vigente, en especial la Ley General de los derechos de las usuarias y
                    los usuarios y de las consumidoras y los consumidores y su reglamento.
                    El Usuario de igual manera podrá informar a MENSAJERIA SHALOM sobre el
                    incumplimiento o mal servicio de los franquiciados o licenciatarios, a fin de que
                    MENSAJERIA SHALOM tenga conocimiento de los hechos.
                    <h3 className="mt-5" style={{ fontWeight: "bold", fontSize: "20px" }}>9. DATOS PERSONALES</h3>
                    Toda la información que reciba MENSAJERIA SHALOM se usará para brindar la
                    mejor experiencia posible al utilizar nuestros Servicios. Algunas de las maneras que
                    se tienen para lograrlo son:
                    • Ayudar al Usuario a acceder eficazmente a la información después de
                    registrarte.
                    • Recordar la información, de modo que el Usuario no tenga que volver a
                    introducirla durante su visita o la próxima vez que visites los Servicios.
                    • Desarrollar, operar, mejorar, proporcionar, mantener y proteger nuestros
                    productos y servicios.
                    • Comunicarse con el Usuario, incluso por correo electrónico.
                    • Supervisar y analizar las tendencias y usos.
                    • Proporciona a los Usuarios información y contenido personalizado, que puede
                    incluir anuncios en línea y otros formatos de marketing.
                    • Diagnosticar o solucionar problemas tecnológicos.
                    <h3 className="mt-5" style={{ fontWeight: "bold", fontSize: "20px" }}>9.- LEY APLICABLE</h3>
                    Los presentes Términos y Condiciones se regirán y serán interpretados de acuerdo
                    con las leyes del Estado Plurinacional de Bolivia, Tratados y normas comunitarias de
                    las que Bolivia sea parte, y resulten aplicables al caso.
                    Si alguna cláusula o disposición de estos Términos y Condiciones es declarada ilegal,
                    la misma deberá ser interpretada de forma independiente y dentro del marco de sí
                    misma. La discusión de la misma no afectará la validez y la aplicabilidad de las
                    provisiones restantes.
                    <h3 className="mt-5" style={{ fontWeight: "bold", fontSize: "20px" }}>10.- MODIFICACION</h3>
                    MENSAJERIA SHALOM se reserva el derecho a modificar los presentes Términos y
                    Condiciones bajo las circunstancias que imperen y permitan el desarrollo y mejora de
                    los servicios de la Plataforma para los usuarios. Adoptada la modificación, ésta será
                    comunicada oportunamente a los Usuarios.
                    <h3 className="mt-5" style={{ fontWeight: "bold", fontSize: "20px" }}>Ultima revisión 22/02/2021</h3>
                </div>


            </div>
        </>
    );
}

export default TermsCondition;